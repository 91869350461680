import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

const BE_ERRORS_NO_TOAST = [
  "invalid origin country",
  "Invalid password or email address",
  "too many login attempts",
  "Password was recently used, please pick another",
  "Password too weak",
  "jwt expired",
  "Invalid CSV file. Please check the errors and try again.",
];

@Injectable({
  providedIn: "root",
})
export class ErrorsImprovedInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      switchMap((response) => {
        if (response instanceof HttpResponse) {
          // Ok 200 with error as payload
          if (response.body?.error) {
            return throwError({ message: response.body.msg });
          }

          if (response.body?.errors) {
            return throwError({ message: response.body.errors[0].message });
          }
        }

        return of(response);
      }),
      catchError((err) => {
        // Http Response Error
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 400: {
              this.toastr.error("Bad Request");
              break;
            }
            case 401: {
              this.toastr.error("Unauthorized");
              break;
            }
            case 403: {
              this.toastr.error("Forbidden");
              break;
            }
            case 404: {
              this.toastr.error("Wrong Resource");
              break;
            }
            case 500: {
              this.toastr.error("Internal Server Error");
              break;
            }
            default: {
              this.toastr.error("Unknown Error");
            }
          }
        } else {
          /**Specific error handled with separate modal in claim-edit component */
          if (!BE_ERRORS_NO_TOAST.includes(err.message))
            this.toastr.error(err.message);
        }
        console.error("Network error: ", err);
        return throwError(err);
      })
    );
  }
}
