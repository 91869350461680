import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { map, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { AuthNewService } from "src/auth/services/auth-new.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuardService {
  constructor(private auth: AuthNewService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.auth.auth$.pipe(
      take(1),
      map((auth) => {
        if (auth) {
          return true;
        }
        this.router.navigate(["/auth/sign-in"], {
          queryParams: { redirectUrl: state.url },
        });
      })
    );
  }
}
