import { Component, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() title;
  @Input() content;

  response = new Subject<boolean>();
  response$ = this.response.asObservable();


  constructor() { }

  ngOnInit(): void {
  }

}
