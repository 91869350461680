<div class="h-screen flex flex-col">
  <!-- <pre> {{ full$ | async | json }} </pre> -->
  <nav
    [ngClass]="isNavZindex ? 'z-[11]' : ''"
    class="top-0 relative dark-purple"
  >
    <!-- *ngIf="loggedIn | async" *ngIf="!(full$ | async)" -->
    <div class="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <!-- <svg-icon svgClass="h-8 text-white" src="assets/svg/logo.svg"></svg-icon> -->
            <a [routerLink]="['/app', 'groups']">
              <svg-icon
                [svgClass]="'h-8 text-white'"
                src="assets/svg/logo-new.svg"
              ></svg-icon>
            </a>
          </div>
          <div *ngIf="loggedIn | async" class="hidden md:block">
            <div class="ml-10 flex items-baseline">
              <a
                *ngxPermissionsOnly="[permissions.SHOW_INVOICES]"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
                [routerLink]="['/app', 'expenses', 'expenses']"
                [routerLinkActive]="['name-btn-active']"
                data-cy="invoices-nav-btn"
              >
                Invoices
              </a>

              <!-- <a
                *ngxPermissionsOnly="[permissions.SHOW_DATA_UPLAOD]"
                data-cy="data-upload-nav-btn"
                href="#"
                [routerLink]="['/app', 'processes']"
                [routerLinkActive]="['name-btn-active']"
                class="transition duration-150 ease-in-out text-white name-btn"
              >
                Data Upload
              </a> -->

              <a
                *ngxPermissionsOnly="[permissions.SHOW_ENTITIES]"
                [routerLink]="['/app', 'groups']"
                [routerLinkActive]="['name-btn-active']"
                data-cy="entities-nav-btn"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                Companies
              </a>

              <a
                *ngxPermissionsOnly="[permissions.SHOW_USERS]"
                [routerLink]="['/app', 'users']"
                [routerLinkActive]="['name-btn-active']"
                data-cy="users-nav-btn"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                Users
              </a>

              <a
                *ngxPermissionsOnly="[permissions.SHOW_CLAIMS]"
                [routerLink]="['/app', 'claims']"
                [routerLinkActive]="['name-btn-active']"
                data-cy="claims-nav-btn"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                Claims
              </a>

              <a
                *ngxPermissionsOnly="[permissions.SHOW_IMAGE_TO_LINE]"
                data-cy="image-to-line-nav-btn"
                [routerLink]="['/app', 'image-to-line']"
                [routerLinkActive]="['name-btn-active']"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                Image To Line
              </a>

              <a
                *ngxPermissionsOnly="[permissions.SHOW_VENDORS]"
                data-cy="vendors-nav-btn"
                [routerLink]="['/app', 'expenses', 'suppliers']"
                [routerLinkActive]="['name-btn-active']"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                Vendors
              </a>

              <a
                *ngxPermissionsOnly="[permissions.SHOW_DASHBOARD]"
                data-cy="dashboard-nav-btn"
                [routerLink]="['/app', 'dashboard']"
                [routerLinkActive]="['name-btn-active']"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                Dashboard
              </a>
              <a
                *ngxPermissionsOnly="[permissions.SHOW_FEES_CONFIGURATION]"
                data-cy="fees-nav-btn"
                [routerLink]="['/app', 'fees-configuration']"
                [routerLinkActive]="['name-btn-active']"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                Fees Configuration
              </a>
              <!-- *ngxPermissionsOnly="[permissions.SHOW_APAI]" -->
              <a
                *ngIf="false"
                data-cy="fees-nav-btn"
                [routerLink]="['/app', 'apai']"
                [routerLinkActive]="['name-btn-active']"
                class="ml-2 transition duration-150 ease-in-out text-white name-btn"
              >
                APAI
              </a>

              <!-- <a href="#" class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-200 hover:text-white hover:bg-blue-600 focus:outline-none focus:text-white focus:bg-blue-600">Team</a>
              <a href="#" class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-200 hover:text-white hover:bg-blue-600 focus:outline-none focus:text-white focus:bg-blue-600">Projects</a>
              <a href="#" class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-200 hover:text-white hover:bg-blue-600 focus:outline-none focus:text-white focus:bg-blue-600">Calendar</a>
              <a href="#" class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-gray-200 hover:text-white hover:bg-blue-600 focus:outline-none focus:text-white focus:bg-blue-600">Reports</a> -->
            </div>
          </div>
        </div>

        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <!--             <button class="p-1 border-2 border-transparent text-yellow-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-gray-700">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
              </svg>
            </button> -->

            <div *ngIf="loggedIn | async" #clickOutside class="ml-3 relative">
              <div>
                <button
                  (click)="openMenu = !openMenu"
                  class="max-w-xs flex items-center name-btn"
                  id="user-menu"
                  aria-label="User menu"
                  aria-haspopup="true"
                >
                  <!-- <span class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                  <span class="font-medium leading-none text-white">TW</span>
                </span> -->
                  <!-- <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""> -->
                  <p class="ml-3 name-btn text-white lg:block">
                    {{ fullname$ | async }}
                  </p>
                  <svg
                    class="hidden flex-shrink-0 ml-1 h-5 w-5 lg:block text-white"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <!-- <div>
                <button (click)="openMenu = !openMenu" class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid" id="user-menu" aria-label="User menu" aria-haspopup="true" x-bind:aria-expanded="open">
                  <img class="h-8 w-8 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
                </button>
              </div> -->
              <div
                *ngIf="openMenu"
                [@inOutAnimation]
                class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-40"
              >
                <div
                  class="py-1 rounded-md bg-white ring-1 ring-black ring-opacity-5"
                >
                  <!-- <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Your Profile</a>-->
                  <a
                    [routerLink]="'/auth/change-password'"
                    (click)="openMenu = false"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >Change Password</a
                  >
                  <a
                    (click)="signOut(); openMenu = false"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    >Sign out</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="-mr-2 flex md:hidden">
          <button
            (click)="open = !open"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white"
          >
            <svg
              class="h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                [ngClass]="{ hidden: open, 'inline-flex': !open }"
                class="inline-flex"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
              <path
                [ngClass]="{ hidden: !open, 'inline-flex': open }"
                class="hidden"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- <div [ngClass]="{'block': open, 'hidden': !open}" class="hidden md:hidden">
      <div class="px-2 pt-2 pb-3 sm:px-3">
        <a href="#" [routerLink]="['/app','processes']" [routerLinkActive]="['name-btn-active']"
          class="block px-3 py-2 rounded-md text-sm font-medium text-gray-200 ocus:outline-none hover:text-white hover:bg-blue-600">
          Processess
        </a>
        <a href="#" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Team</a>
        <a href="#" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Projects</a>
        <a href="#" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Calendar</a>
        <a href="#" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Reports</a>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5">
          <div class="flex-shrink-0">
            <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-white">Tom Cook</div>
            <div class="mt-1 text-sm font-medium leading-none text-gray-400">tom@example.com</div>
          </div>
        </div>
        <div class="mt-3 px-2">
          <a href="#" class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Your Profile</a>
          <a href="#" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Settings</a>
          <a href="#" class="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700">Sign out</a>
        </div>
      </div>
    </div> -->
    <!-- <hr class="border-b-2 border-yellow-400"/> -->

    <!-- <div class="progress absolute bottom-0 dark-purple">
      <div
        *ngIf="loading$ | async"
        class="progress-bar-custom bg-secondary"
      ></div>
    </div> -->
  </nav>

  <!-- <header class="bg-gray-400 shadow">
    <div class="max-w-screen-2xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
      <h2 class="text-xl leading-tight text-white">
        Processes
      </h2>
    </div>
  </header> -->
  <main class="flex-1 overflow-auto relative">
    <!-- <div class="max-w-screen-2xl mx-auto py-6 sm:px-6 lg:px-8"> -->
    <app-loader *ngIf="loading$ | async" />
    <router-outlet></router-outlet>

    <!-- </div> -->
  </main>

  <!-- <span
    *ngIf="version"
    class="absolute bottom-0 m-2 left-0 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-blue-100 text-blue-800"
  >
    {{ version }}, {{ role }}, {{ expires$ | async }}
  </span> -->
</div>
