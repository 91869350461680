import {gql} from 'apollo-angular';


export default gql`
mutation RemoveUser($userId: Int!) {
  removeUser(userId: $userId) {
    id
    firstName
    lastName
    email
  }
}`;
