import { ApaiFilter } from "./apai-state.model";

export class SetPage {
  static readonly type = "[Apai] Set Page";
  constructor(public page: number) {}
}

export class SetFilter {
  static readonly type = "[Apai] Set Filter";
  constructor(public filter: Partial<ApaiFilter>) {
    // console.log(filter);
  }
}

export class ResetFilter {
  static readonly type = "[Apai] Reset Filter";
  constructor() {
    // console.log(filter);
  }
}
