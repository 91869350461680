<form [formGroup]="form" class="h-full" (submit)="onSubmit()">
  <div class="flex items-center h-full">
    <div>
      <div>
        <p>
          <span class="sign strong mr-1">Reset</span>&nbsp;
          <span class="sign light">Your Password</span>
        </p>
      </div>
      <div
        [ngClass]="{ 'invalid-input': isInvalid }"
        class="sign-in-input relative w-[22rem] h-[3.75rem] mt-8"
      >
        <div class="sign-in-icon">
          <svg
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.304 2.40553L1.5 1.78984V2.8025V14V14.5H2H20H20.5V14V2.804V1.7904L19.6957 2.40725L11.2953 8.85H10.7195L2.304 2.40553ZM18.8498 2.39632L20.0151 1.5H18.545H3.455H1.98487L3.15016 2.39632L10.6952 8.19982L11 8.4343L11.3048 8.19982L18.8498 2.39632ZM1 14.5429V1.45711L1.45711 1H20.5429L21 1.45711V14.5429L20.5429 15H1.45711L1 14.5429Z"
              fill="#333333"
              stroke="#333333"
            />
          </svg>
        </div>
        <input
          type="email"
          formControlName="email"
          placeholder="Email Address"
          autocomplete="username"
          aria-label="EmailAddress"
          class="autofill"
        />
      </div>
      <div *ngIf="isInvalid" class="error-msg mt-1">
        Email address is not valid
      </div>
      <div class="flex items-center justify-between w-[22rem] mt-4">
        <button
          [disabled]="isFormInvalid"
          class="login-btn flex block justify-center items-center w-[16.875rem] h-[3.75rem]"
        >
          <svg
            *ngIf="loading; else notLoading"
            class="animate-spin"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_914_5279" fill="white">
              <path
                d="M15 1.25168e-06C17.9667 1.122e-06 20.8668 0.879735 23.3336 2.52796C25.8003 4.17618 27.7229 6.51886 28.8582 9.25975C29.9935 12.0006 30.2906 15.0166 29.7118 17.9264C29.133 20.8361 27.7044 23.5088 25.6066 25.6066C23.5088 27.7044 20.8361 29.133 17.9264 29.7118C15.0166 30.2906 12.0006 29.9935 9.25975 28.8582C6.51886 27.7229 4.17618 25.8003 2.52796 23.3336C0.879734 20.8668 -5.61369e-07 17.9667 -6.55671e-07 15L3 15C3 17.3734 3.70379 19.6935 5.02236 21.6668C6.34094 23.6402 8.21509 25.1783 10.4078 26.0866C12.6005 26.9948 15.0133 27.2324 17.3411 26.7694C19.6689 26.3064 21.8071 25.1635 23.4853 23.4853C25.1635 21.8071 26.3064 19.6689 26.7694 17.3411C27.2324 15.0133 26.9948 12.6005 26.0866 10.4078C25.1783 8.21509 23.6402 6.34094 21.6668 5.02236C19.6935 3.70379 17.3734 3 15 3L15 1.25168e-06Z"
              />
            </mask>
            <path
              d="M15 1.25168e-06C17.9667 1.122e-06 20.8668 0.879735 23.3336 2.52796C25.8003 4.17618 27.7229 6.51886 28.8582 9.25975C29.9935 12.0006 30.2906 15.0166 29.7118 17.9264C29.133 20.8361 27.7044 23.5088 25.6066 25.6066C23.5088 27.7044 20.8361 29.133 17.9264 29.7118C15.0166 30.2906 12.0006 29.9935 9.25975 28.8582C6.51886 27.7229 4.17618 25.8003 2.52796 23.3336C0.879734 20.8668 -5.61369e-07 17.9667 -6.55671e-07 15L3 15C3 17.3734 3.70379 19.6935 5.02236 21.6668C6.34094 23.6402 8.21509 25.1783 10.4078 26.0866C12.6005 26.9948 15.0133 27.2324 17.3411 26.7694C19.6689 26.3064 21.8071 25.1635 23.4853 23.4853C25.1635 21.8071 26.3064 19.6689 26.7694 17.3411C27.2324 15.0133 26.9948 12.6005 26.0866 10.4078C25.1783 8.21509 23.6402 6.34094 21.6668 5.02236C19.6935 3.70379 17.3734 3 15 3L15 1.25168e-06Z"
              fill="#7252AE"
              stroke="#7252AE"
              stroke-width="2"
              mask="url(#path-1-inside-1_914_5279)"
            />
          </svg>
          <ng-template #notLoading>Reset Password</ng-template>
        </button>
        <a [routerLink]="['../sign-in']" class="block h-full back-btn px-2"
          >Back</a
        >
      </div>
    </div>
  </div>
</form>
