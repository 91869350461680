import {gql} from 'apollo-angular';


export default gql`
mutation UpdateUser($updateUserInput: UpdateUserInput) {
  updateUser(
    input: $updateUserInput
  ) {
    id
    firstName
    lastName
    email
    role
    companies {
      id
      name
    }
    status
  }
}
`;
