import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppStateService } from 'src/app/services/app-state.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  form = this.fb.group({
    token: ""
  });

  constructor(private fb: UntypedFormBuilder, private store: AppStateService, private router: Router) { }

  ngOnInit(): void {

  }

  submit() {
    console.log(this.form.get('token').value);

    this.store.set('token', this.form.get('token').value);
    this.router.navigate(['/']);

  }

}
