import { Component, OnInit } from "@angular/core";
import { catchError, tap } from "rxjs/operators";
import { EMPTY } from "rxjs";
import { Router } from "@angular/router";
import { AuthNewService } from "src/auth/services/auth-new.service";

@Component({
  selector: "app-reset-link-sent",
  templateUrl: "./reset-link-sent.component.html",
  styleUrls: ["./reset-link-sent.component.scss"],
})
export class ResetLinkSentComponent implements OnInit {
  emailSentAgain = false;
  userEmail: string;
  loading = false;

  constructor(private auth: AuthNewService, private router: Router) {
    this.userEmail =
      this.router.getCurrentNavigation()?.extras?.state?.userEmail || "";
  }

  ngOnInit(): void {}

  sendEmailAgain() {
    this.loading = true;
    this.auth
      .forgotPassword(this.userEmail)
      .pipe(
        tap(() => {
          this.loading = false;
          this.emailSentAgain = true;
        }),
        catchError((error) => {
          this.loading = false;
          return EMPTY;
        })
      )
      .subscribe();
  }
}
