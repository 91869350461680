import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputDateDirective } from "./input-date.directive";
import { ButtonDirective } from "./button.directive";
import { InputDirective } from "./input.directive";
import { AutofocusDirective } from "./autofocus.directive";
import { GooglePlaceDirective } from "./google-places-directive/google-places.directive";

@NgModule({
  declarations: [
    InputDateDirective,
    ButtonDirective,
    InputDirective,
    AutofocusDirective,
    GooglePlaceDirective,
  ],
  imports: [CommonModule],
  exports: [
    InputDateDirective,
    ButtonDirective,
    InputDirective,
    AutofocusDirective,
    GooglePlaceDirective,
  ],
})
export class AppDirectivesModule {}
