import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { AuthNewService } from "src/auth/services/auth-new.service";
import { catchError, switchMap } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(private auth: AuthNewService) {}

  private serializeDates(value: any): any {
    if (value === null || value === undefined) {
      return value;
    }

    if (typeof value !== "object") {
      return value;
    }

    if (Array.isArray(value)) {
      return value.map((item) => this.serializeDates(item));
    }

    if (value instanceof Date) {
      return this.formatDate(value);
    }

    const serializedBody = {};
    for (const [key, val] of Object.entries(value)) {
      serializedBody[key] = this.serializeDates(val);
    }
    return serializedBody;
  }

  private formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const idToken = localStorage.getItem("access_token");

    const isApiUrl = request.url.startsWith(environment.gw);
    let req: HttpRequest<any>;
    /**Do not mess with FormData */
    if (request.body instanceof FormData) {
      req = request.clone();
    } else {
      /**If JS Date serialize it to yyyy-mm-dd */
      req = request.clone({
        body: this.serializeDates(request.body),
      });
    }

    if (idToken && isApiUrl && !this.isTokenExpired("access_token")) {
      req = this.addTokenToRequest(req, idToken);
      return this.handleRequestAndRefreshIfErr(req, next);
    } else if (
      isApiUrl &&
      this.isTokenExpired("access_token") &&
      !this.isTokenExpired("refresh_token")
    ) {
      if (!req.url?.includes("auth")) {
        return this.auth.refresh().pipe(
          switchMap(() => {
            const newAccessToken = this.getToken();
            if (newAccessToken) {
              let newRequest = this.addTokenToRequest(req, newAccessToken);

              return this.handleRequestAndRefreshIfErr(newRequest, next);
            } else {
              return throwError("Unable to obtain new access token.");
            }
          }),
          catchError((error) => {
            this.auth.signOut();
            return throwError(error);
          })
        );
      } else {
        return this.handleRequestAndRefreshIfErr(req, next);
      }
    } else if (isApiUrl) {
      return this.handleRequestAndRefreshIfErr(req, next);
    } else {
      return next.handle(req);
    }
  }

  private isTokenExpired(type: "refresh_token" | "access_token") {
    const token = localStorage.getItem(type);

    if (!token) return false;
    const parsedToken = JSON.parse(atob(token.split(".")[1]));
    const exp = Number(parsedToken.exp * 1000);

    return exp < Date.now();
  }

  getToken() {
    return localStorage.getItem("access_token");
  }

  private addTokenToRequest(
    request: HttpRequest<any>,
    token: string
  ): HttpRequest<any> {
    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }

  private handleRequestAndRefreshIfErr(
    request: HttpRequest<any>,
    next: HttpHandler
  ) {
    let req = request;
    return next.handle(req).pipe(
      catchError((err) => {
        if (
          [401, 403].includes(err.status) &&
          this.auth.userValue &&
          !this.isTokenExpired("refresh_token")
        )
          return this.auth.refresh().pipe(
            switchMap(() => {
              const access_token = this.getToken();
              req = req.clone({
                setHeaders: { Authorization: `Bearer ${access_token}` },
              });
              return next.handle(req);
            })
          );

        return throwError(err);
      }),
      catchError((err) => {
        if ([401, 403].includes(err.status) && this.auth.userValue) {
          // auto logout if 401 or 403 response returned from api
          this.auth.signOut();
        }
        return throwError(err);
      })
    );
  }
}
