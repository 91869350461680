import {gql} from 'apollo-angular';


export default gql`
query Users($list: UsersList!, $filter: UsersFilter!){
  users(
    list: $list
    filter: $filter
  )
  {
    metaData {
      count
    }
    objects {
      id
      firstName
      lastName
      email
      role
      companies {
         id
         name
         countryCode
      }
      status
    }
  }
}
`;
