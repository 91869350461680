import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { catchError, finalize, first, tap } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthNewService } from "src/auth/services/auth-new.service";
import { EMPTY } from "rxjs";
import { emailPattern, passwordPattern } from "src/app/app.constants";
import { ApaiEnabledService } from "../../../app/modules/apai/services/apai-enabled.service";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
  loading = false;
  @ViewChild("password") passwordInput: ElementRef<HTMLInputElement>;
  errorMessage: string;
  disableLogin = false;

  passwordType = "password";

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthNewService,
    private router: Router,
    private route: ActivatedRoute,
    private apaiEnabledService: ApaiEnabledService
  ) {
    this.form = this.fb.group({
      username: [
        "",
        { validators: [Validators.required, Validators.pattern(emailPattern)] },
      ],
      password: [
        "",
        [Validators.required, Validators.pattern(passwordPattern)],
      ],
    });
  }

  get isDisabled() {
    return (
      this.form.controls.username?.invalid ||
      this.form.controls.password?.invalid
    );
  }

  ngOnInit(): void {
    this.form.controls.password.valueChanges.subscribe(() => {
      this.errorMessage = undefined;
    });
  }

  onSubmit() {
    if (this.form.invalid) return;

    const { username, password } = this.form.value;

    this.loading = true;

    this.auth
      .signIn(username, password)
      .pipe(
        first(),
        tap((auth) => {
          const routeSnapshot = this.route.snapshot;
          const redirectUrl = routeSnapshot.queryParams["redirectUrl"];
          const role = auth.payload.role;
          this.apaiEnabledService.fetchConfig();
          if (redirectUrl) {
            this.router.navigate([redirectUrl], {
              state: { preventLoader: true },
            });
          } else if (role === "Admin") {
            this.router.navigate(["/app/expenses/expenses"], {
              state: { preventLoader: true },
            });
          } else if (role === "Analyst") {
            this.router.navigate(["/app/expenses/expenses"], {
              state: { preventLoader: true },
            });
          } else if (role === "User") {
            this.router.navigate(["/app/dashboard"], {
              state: { preventLoader: true },
            });
          } else {
            this.router.navigate(["/auth/sign-in"], {
              state: { preventLoader: true },
            });
          }
        }),
        catchError((e) => {
          console.log("error ", e);
          this.errorMessage = e.message;
          if (e.message === "Invalid password or email address") {
            this.errorMessage = "Incorrect email or password";
          }
          if (e.message === "too many login attempts") {
            this.disableLogin = true;
            this.errorMessage = "Too many attempts, please try later";
          }

          this.loading = false;

          return EMPTY;
        })
      )
      .subscribe();
  }

  onTypeChange(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    switch (this.passwordType) {
      case "password":
        return (this.passwordType = "text");
      case "text":
        return (this.passwordType = "password");
      default: {
        return (this.passwordType = "password");
      }
    }
  }
}
