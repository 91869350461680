import { gql } from "apollo-angular";

export default gql`
  mutation Login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      access_token
      refresh_token
      payload {
        firstName
        lastName
        email
        role
        userId
        companiesIds
      }
    }
  }
`;
