import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { PasswordResetFormComponent } from "./views/password-reset-form/password-reset-form.component";
import { RequestResetComponent } from "./views/request-reset/request-reset.component";
import { ResetLinkSentComponent } from "./views/reset-link-sent/reset-link-sent.component";
import { SignInComponent } from "./views/sign-in/sign-in.component";
import { ResetLinkResolver } from "./resolvers/reset-link.resolver";
import { SignUpComponent } from "./views/sign-up/sign-up.component";
import { ShellAuthComponent } from "./views/shell/shell.component";

const routes: Routes = [
  {
    path: "",
    component: ShellAuthComponent,
    children: [
      { path: "", redirectTo: "sign-in", pathMatch: "full" },
      { path: "sign-in", component: SignInComponent },
      { path: "change-password", component: ChangePasswordComponent },
      { path: "sign-up", component: SignUpComponent },
      {
        path: "request-reset",
        pathMatch: "full",
        component: RequestResetComponent,
      },
      {
        path: "request-reset/complete",
        component: ResetLinkSentComponent,
        resolve: { userEmail: ResetLinkResolver },
      },
      { path: "request-reset/:token", component: PasswordResetFormComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
