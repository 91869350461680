<div class="h-full flex items-center relative">
  <div class="flex justify-center items-center flex-col w-full content-wrap">
    <div>
      <svg
        width="56"
        height="40"
        viewBox="0 0 56 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="svgSize"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 2L2 0H54L56 2V38L54 40H2L0 38V2ZM4 6.14V36H52V6.144L29.24 23.6H26.8L4 6.14ZM48.12 4H7.88L28 19.476L48.12 4Z"
          fill="#241534"
        />
      </svg>
    </div>
    <p class="text text-bold mt-12">
      We sent an email to {{ userEmail ? userEmail : "N/A" }} with a
    </p>
    <p class="text text-bold">link to reset your password</p>
    <p class="text mt-2">If you can't see the email in your inbox please</p>
    <p class="text">check your <span class="text-bold">spam</span> folder</p>
    <div class="flex items-center gap-x-2 mt-10">
      <button
        (click)="sendEmailAgain()"
        class="action-btn outlined w-[190px] h-[60px] flex items-center justify-center"
        [disabled]="!userEmail || emailSentAgain"
      >
        <svg
          *ngIf="loading"
          class="animate-spin"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask id="path-1-inside-1_914_5279" fill="white">
            <path
              d="M15 1.25168e-06C17.9667 1.122e-06 20.8668 0.879735 23.3336 2.52796C25.8003 4.17618 27.7229 6.51886 28.8582 9.25975C29.9935 12.0006 30.2906 15.0166 29.7118 17.9264C29.133 20.8361 27.7044 23.5088 25.6066 25.6066C23.5088 27.7044 20.8361 29.133 17.9264 29.7118C15.0166 30.2906 12.0006 29.9935 9.25975 28.8582C6.51886 27.7229 4.17618 25.8003 2.52796 23.3336C0.879734 20.8668 -5.61369e-07 17.9667 -6.55671e-07 15L3 15C3 17.3734 3.70379 19.6935 5.02236 21.6668C6.34094 23.6402 8.21509 25.1783 10.4078 26.0866C12.6005 26.9948 15.0133 27.2324 17.3411 26.7694C19.6689 26.3064 21.8071 25.1635 23.4853 23.4853C25.1635 21.8071 26.3064 19.6689 26.7694 17.3411C27.2324 15.0133 26.9948 12.6005 26.0866 10.4078C25.1783 8.21509 23.6402 6.34094 21.6668 5.02236C19.6935 3.70379 17.3734 3 15 3L15 1.25168e-06Z"
            />
          </mask>
          <path
            d="M15 1.25168e-06C17.9667 1.122e-06 20.8668 0.879735 23.3336 2.52796C25.8003 4.17618 27.7229 6.51886 28.8582 9.25975C29.9935 12.0006 30.2906 15.0166 29.7118 17.9264C29.133 20.8361 27.7044 23.5088 25.6066 25.6066C23.5088 27.7044 20.8361 29.133 17.9264 29.7118C15.0166 30.2906 12.0006 29.9935 9.25975 28.8582C6.51886 27.7229 4.17618 25.8003 2.52796 23.3336C0.879734 20.8668 -5.61369e-07 17.9667 -6.55671e-07 15L3 15C3 17.3734 3.70379 19.6935 5.02236 21.6668C6.34094 23.6402 8.21509 25.1783 10.4078 26.0866C12.6005 26.9948 15.0133 27.2324 17.3411 26.7694C19.6689 26.3064 21.8071 25.1635 23.4853 23.4853C25.1635 21.8071 26.3064 19.6689 26.7694 17.3411C27.2324 15.0133 26.9948 12.6005 26.0866 10.4078C25.1783 8.21509 23.6402 6.34094 21.6668 5.02236C19.6935 3.70379 17.3734 3 15 3L15 1.25168e-06Z"
            fill="#7252AE"
            stroke="#7252AE"
            stroke-width="2"
            mask="url(#path-1-inside-1_914_5279)"
          />
        </svg>
        <ng-container *ngIf="!loading && !emailSentAgain">Resend</ng-container>
        <svg
          *ngIf="emailSentAgain"
          width="33"
          height="26"
          viewBox="0 0 33 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.2586 23.9358L30.7197 4.87793C31.9492 3.06877 33.1069 2.35072 31.3682 0.998962C29.6299 -0.352752 28.7833 1.15677 27.2588 2.73651L11.9233 21.0251L6 15.5C4.32321 13.86 3.04486 12.36 1.36807 14C-0.308719 15.64 1.77763 17.36 3.45442 19L8.88733 24.3122C9.69425 25.0952 11.9233 25.5251 11.9233 25.5251H12.1799C13.39 25.4465 14.5094 24.8689 15.2586 23.9358Z"
            fill="#7252AE"
          />
        </svg>
      </button>
      <button
        [routerLink]="['../../sign-in']"
        class="action-btn w-[190px] h-[60px]"
      >
        Back to login
      </button>
    </div>
  </div>
</div>
