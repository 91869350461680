import { Component, OnInit } from "@angular/core";
import { ToastPackage, ToastrService } from "ngx-toastr";

@Component({
  selector: "app-toast-component",
  templateUrl: "./toast-component.component.html",
  styleUrls: ["./toast-component.component.scss"],
})
export class ToastComponent implements OnInit {
  constructor(
    public toastPackage: ToastPackage,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    const timeout = this.toastPackage.config?.timeOut || 5000;
    setTimeout(() => {
      this.removeToast();
    }, timeout);
  }

  removeToast() {
    this.toastrService.remove(this.toastPackage.toastId);
  }

  get toastType() {
    return this.toastPackage.toastType;
  }
}
