import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY, catchError, tap } from "rxjs";
import { passwordPattern } from "src/app/app.constants";
import { MustMatch } from "src/app/utils/validators/must-match";
import { AuthNewService } from "src/auth/services/auth-new.service";

@Component({
  selector: "app-password-reset-form",
  templateUrl: "./password-reset-form.component.html",
  styleUrls: ["./password-reset-form.component.scss"],
})
export class PasswordResetFormComponent implements OnInit {
  errorMessage: string;
  form = this.fb.group(
    {
      newPassword: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(passwordPattern),
          ],
          updateOn: "change",
        },
      ],
      newPasswordRetype: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(passwordPattern),
          ],
          updateOn: "change",
        },
      ],
    },
    {
      validators: [MustMatch("newPassword", "newPasswordRetype")],
      updateOn: "change",
    }
  );

  loading = false;

  get formNewPaswordControl() {
    return this.form.controls.newPassword;
  }

  get formNewPasswordRetype() {
    return this.form.controls.newPasswordRetype;
  }

  get isRecentlyUsed() {
    return (
      this.errorMessage === "Password was recently used, please pick another"
    );
  }

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthNewService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() => {
      this.errorMessage = undefined;
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.loading = true;
    this.auth
      .setNewPassword(this.form.value.newPassword, this.token)
      .pipe(
        tap(() => {
          this.router.navigate(["/"], {
            state: { preventLoader: true },
          });
        }),
        catchError((e) => {
          this.errorMessage = e.message;
          if (e.message === "jwt expired") {
            this.errorMessage = "Link expired, please request a new one";
          }

          this.loading = false;
          return EMPTY;
        })
      )
      .subscribe();
  }

  get token(): string {
    return this.route.snapshot.paramMap.get("token");
  }
}
