import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoaderComponent } from "./loader/loader.component";
import { AutoCompleteComponent } from "./auto-complete/auto-complete.component";
import { FormFieldComponent } from "./form-field/form-field.component";
import { TabComponent } from "./tab/tab.component";
import { TabPanelComponent } from "./tab-panel/tab-panel.component";
import { CardComponent } from "./card/card.component";
import { CardHeaderComponent } from "./card/card-header.component";
import { CardFooterComponent } from "./card/card-footer.component";
import { CardSubHeaderComponent } from "./card/card-sub-header.component";
import { CardContentComponent } from "./card/card-content.component";
import { ToggleComponent } from "./toggle/toggle.component";
import { ParamDirective } from "./param.directive";
import { NgxImageZoomModule } from "./ngx-image-xoom/ngx-image-xoom.module";
import { NgxImageZoomComponent } from "./ngx-image-xoom/ngx-image-zoom.component";
import { ShortNumberPipe } from "../pipes/short-number.pipe";
import { ModalComponent } from "./modal/modal.component";
import { AddressInputComponent } from "./address-input/address-input.component";
// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AutocompleteFormFilterComponent } from "./autocomplete-form-filter/autocomplete-form-filter.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { ImgFallbackDirective } from "./img-fallback.directive";
import { TableComponent } from "./table/table.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ColumnDefDirective } from "./table/column-def.directive";
import { DisableControlDirective } from "./disable-control.directive";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ImageRotateDirective } from "./image-rotate.directive";
import { RouterModule } from "@angular/router";
import { ToggleButtonComponent } from "./toggle-button/toggle-button.component";
import { ButtonComponent } from "./button/button.component";
import { Button } from "selenium-webdriver";
import { AutocompleteMultiFormFilterComponent } from "./autocomplete-multi-form-filter/autocomplete-multi-form-filter.component";
import { AppDirectivesModule } from "../directives/app-directives.module";
import { SpinnerComponent } from "./spinner/spinner.component";
import { DatePickerComponent } from "../shared/date-picker/date-picker.component";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { LoaderSvgComponent } from "./loader-svg/loader-svg.component";
import { AccountsSearchComponent } from "./accounts-search/accounts-search.component";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { TooltipDirective } from "./tooltip/tooltip.directive";
import { CurrencyPasteDirective } from "./currency-paste.directive";
import { CurrencyPasteNewDirective } from "./currency-paste-new.directive";

@NgModule({
  declarations: [
    LoaderComponent,
    AutoCompleteComponent,
    AccountsSearchComponent,
    FormFieldComponent,
    TabComponent,
    TabPanelComponent,
    CardComponent,
    CardHeaderComponent,
    CardFooterComponent,
    CardSubHeaderComponent,
    CardContentComponent,
    ToggleComponent,
    ParamDirective,
    ShortNumberPipe,
    ModalComponent,
    ToggleButtonComponent,
    ButtonComponent,
    AddressInputComponent,
    AutocompleteFormFilterComponent,
    AutocompleteMultiFormFilterComponent,
    ImgFallbackDirective,
    TableComponent,
    ColumnDefDirective,
    DisableControlDirective,
    ImageRotateDirective,
    ToggleButtonComponent,
    ButtonComponent,
    SpinnerComponent,
    DatePickerComponent,
    LoaderSvgComponent,
    TooltipComponent,
    TooltipDirective,

    CurrencyPasteNewDirective,
  ],
  imports: [
    CommonModule,
    NgxImageZoomModule,
    NgSelectModule,
    // GooglePlaceModule,
    ReactiveFormsModule,
    AngularSvgIconModule,
    RouterModule,
    MatTooltipModule,
    AppDirectivesModule,
    FormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
  ],
  exports: [
    LoaderComponent,
    AutoCompleteComponent,
    FormFieldComponent,
    TabPanelComponent,
    TabComponent,
    CardComponent,
    CardHeaderComponent,
    CardFooterComponent,
    CardSubHeaderComponent,
    CardContentComponent,
    ToggleComponent,
    ParamDirective,
    NgxImageZoomComponent,
    ShortNumberPipe,
    AddressInputComponent,
    AutocompleteFormFilterComponent,
    AutocompleteMultiFormFilterComponent,
    ImgFallbackDirective,
    TableComponent,
    ColumnDefDirective,
    DisableControlDirective,
    ImageRotateDirective,
    ToggleButtonComponent,
    ButtonComponent,
    SpinnerComponent,
    DatePickerComponent,
    LoaderSvgComponent,
    AccountsSearchComponent,
    TooltipComponent,
    TooltipDirective,
    CurrencyPasteNewDirective,
  ],
})
export class ComponentsModule {}
