export enum UIPermissions {
  SHOW_CLAIMS = "SHOW_CLAIMS",
  EDIT_CLAIM = "EDIT_CLAIM",
  CREATE_CLAIM = "CREATE_CLAIM",
  DELETE_CLAIM = "DELETE_CLAIM",

  LINK_CLAIM = "LINK_CLAIM",
  UNLINK_CLAIM = "UNLINK_CLAIM",
  APPLY_PARTIAL_EXAMPTION = "APPLY_PARTIAL_EXAMPTION",
  SUBMIT_TO_HMRC = "SUBMIT_TO_HMRC",
  GENERATE_CLAIM_FORM = "GENERATE_CLAIM_FORM",
  GENERATE_INVOICE_BOOK = "GENERATE_INVOICE_BOOK",
  GENERATE_BACKUP = "GENERATE_BACKUP",
  EXPORT_CLAIMS = "EXPORT_CLAIMS",
  EXPORT_DECISION_LETTER = "EXPORT_DECISION_LETTER",
  EXPORT_DOMESTIC_VAT_REPORT = "EXPORT_DOMESTIC_VAT_REPORT",
  EXPORT_CLAIM_SUMMARY = "EXPORT_CLAIM_SUMMARY",
  DELETE_CLAIM_DOCUMENT = "DELETE_CLAIM_DOCUMENT",

  SHOW_RECONCILIATION = "SHOW_RECONCILIATION",

  SHOW_INVOICES = "SHOW_INVOICES",
  CREATE_INVOICE = "CREATE_INVOICE",
  EDIT_INVOICE = "EDIT_INVOICE",
  DELETE_INVOICE = "DELETE_INVOICE",
  EXPORT_INVOICES = "EXPORT_INVOICES",
  BULK_UPDATE_INVOICE = "BULK_UPDATE_INVOICE",

  SHOW_DATA_UPLAOD = "SHOW_DATA_UPLAOD",
  CREATE_PROCESS = "CREATE_PROCESS",
  SHOW_PROCESS = "SHOW_PROCESS",

  SHOW_ENTITIES = "SHOW_ENTITIES",
  CREATE_ENTITY = "CREATE_ENTITY",
  EDIT_ENTITY = "EDIT_ENTITY",
  DELETE_ENTITY = "DELETE_ENTITY",
  SHOW_CONNECTIONS = "SHOW_CONNECTIONS",
  SHOW_PARTIAL_EXAMPTION = "SHOW_PARTIAL_EXAMPTION",
  SHOW_APAI = "SHOW_APAI",

  SHOW_IMAGE_TO_LINE = "SHOW_IMAGE_TO_LINE",

  SHOW_DASHBOARD = "SHOW_DASHBOARD",

  SHOW_USERS = "SHOW_USERS",
  EDIT_USER = "EDIT_USER",
  CREATE_USER = "CREATE_USER",
  DELETE_USER = "DELETE_USER",
  INVITE_USER = "INVITE_USER",

  SHOW_VENDORS = "SHOW_VENDORS",
  EDIT_VENDOR = "EDIT_VENDOR",
  CREATE_VENDOR = "CREATE_VENDOR",
  DELETE_VENDOR = "DELETE_VENDOR",
  SHOW_FEES_CONFIGURATION = "SHOW_FEES_CONFIGURATION",
  CREATE_COMPANY = "CREATE_COMPANY",

  DELETE_TAX_CERTIFICATE = "DELETE_TAX_CERTIFICATE",
}

const claimsPermissions = [
  UIPermissions.SHOW_CLAIMS,
  UIPermissions.EDIT_CLAIM,
  UIPermissions.CREATE_CLAIM,
  UIPermissions.DELETE_CLAIM,
  UIPermissions.LINK_CLAIM,
  UIPermissions.UNLINK_CLAIM,
  UIPermissions.APPLY_PARTIAL_EXAMPTION,
  UIPermissions.SUBMIT_TO_HMRC,
  UIPermissions.GENERATE_CLAIM_FORM,
  UIPermissions.GENERATE_INVOICE_BOOK,
  UIPermissions.GENERATE_BACKUP,
  UIPermissions.EXPORT_CLAIMS,
  UIPermissions.EXPORT_DECISION_LETTER,
  UIPermissions.EXPORT_DOMESTIC_VAT_REPORT,
  UIPermissions.EXPORT_CLAIM_SUMMARY,
  UIPermissions.DELETE_CLAIM_DOCUMENT,
  UIPermissions.SHOW_RECONCILIATION,
];

const invoicesPermissions = [
  UIPermissions.SHOW_INVOICES,
  UIPermissions.CREATE_INVOICE,
  UIPermissions.EDIT_INVOICE,
  UIPermissions.DELETE_INVOICE,
  UIPermissions.EXPORT_INVOICES,
  UIPermissions.BULK_UPDATE_INVOICE,
];

const usersPermissions = [
  UIPermissions.SHOW_USERS,
  UIPermissions.EDIT_USER,
  UIPermissions.CREATE_USER,
  UIPermissions.DELETE_USER,
  UIPermissions.INVITE_USER,
];

const vendorsPermissions = [
  UIPermissions.SHOW_VENDORS,
  UIPermissions.EDIT_VENDOR,
  UIPermissions.CREATE_VENDOR,
  UIPermissions.DELETE_VENDOR,
];

const entitiesPermissions = [
  UIPermissions.SHOW_ENTITIES,
  UIPermissions.CREATE_ENTITY,
  UIPermissions.EDIT_ENTITY,
  UIPermissions.DELETE_ENTITY,
  UIPermissions.SHOW_CONNECTIONS,
  UIPermissions.SHOW_PARTIAL_EXAMPTION,
];

const dashboardPermissions = [UIPermissions.SHOW_DASHBOARD];

const dataUploadPermissions = [
  UIPermissions.SHOW_DATA_UPLAOD,
  UIPermissions.CREATE_PROCESS,
  UIPermissions.SHOW_PROCESS,
];

const imageToLinePermissions = [UIPermissions.SHOW_IMAGE_TO_LINE];

const feesConfigurationPermissions = [UIPermissions.SHOW_FEES_CONFIGURATION];

export const ALL_PERMISSIONS = [
  ...claimsPermissions,
  ...dashboardPermissions,
  ...entitiesPermissions,
  ...invoicesPermissions,
  ...usersPermissions,
  ...vendorsPermissions,
  ...dataUploadPermissions,
  ...imageToLinePermissions,
  ...feesConfigurationPermissions,
  UIPermissions.SHOW_APAI,
  UIPermissions.CREATE_COMPANY,
  UIPermissions.DELETE_TAX_CERTIFICATE,
];

export const ANALYST_PERMISSIONS = [
  UIPermissions.SHOW_INVOICES,
  UIPermissions.CREATE_INVOICE,
  UIPermissions.EDIT_INVOICE,
  UIPermissions.BULK_UPDATE_INVOICE,

  UIPermissions.SHOW_VENDORS,
  UIPermissions.EDIT_VENDOR,
  UIPermissions.CREATE_VENDOR,
  UIPermissions.DELETE_VENDOR,

  UIPermissions.SHOW_IMAGE_TO_LINE,
  UIPermissions.EXPORT_INVOICES,
  UIPermissions.CREATE_COMPANY,
  UIPermissions.DELETE_TAX_CERTIFICATE,
];

export const USER_PERMISSIONS = [
  UIPermissions.SHOW_CLAIMS,
  UIPermissions.EXPORT_CLAIMS,
  UIPermissions.EXPORT_DECISION_LETTER,

  UIPermissions.SHOW_INVOICES,
  UIPermissions.EXPORT_INVOICES,

  UIPermissions.SHOW_ENTITIES,
  UIPermissions.CREATE_ENTITY,
  UIPermissions.EDIT_ENTITY,
  UIPermissions.DELETE_ENTITY,

  UIPermissions.SHOW_CONNECTIONS,

  UIPermissions.SHOW_DASHBOARD,
];
