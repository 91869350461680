<div class="flex h-full items-center justify-center py-12">
  <div>
    <div class="bg-white px-8 py-8 rounded-lg bg-opacity-80">
      <div *ngIf="validToken; else expiredTokenTpl">
        <div class="mt-4">
          <p>
            <span class="sign strong mr-1">Create</span>
            <span class="sign light">your new account</span>
          </p>
        </div>
        <form [formGroup]="form" (submit)="onSubmit()">
          <div class="flex flex-col items-center">
            <div class="sign-in-input relative w-[310px] h-[55px] mt-4">
              <div class="sign-in-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                    stroke="#333333"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22"
                    stroke="#333333"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                type="text"
                formControlName="firstName"
                placeholder="First Name"
                autocomplete="given-name"
                aria-label="First Name"
              />
            </div>

            <div class="sign-in-input relative w-[310px] h-[55px] mt-4">
              <div class="sign-in-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                    stroke="#333333"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M20.5901 22C20.5901 18.13 16.7402 15 12.0002 15C7.26015 15 3.41016 18.13 3.41016 22"
                    stroke="#333333"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <input
                type="text"
                formControlName="lastName"
                placeholder="Last Name"
                autocomplete="family-name"
                aria-label="Last Name"
              />
            </div>

            <div class="sign-in-input relative w-[310px] h-[55px] mt-4">
              <div class="sign-in-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z"
                    fill="#333333"
                  />
                </svg>
              </div>
              <input
                [type]="passwordType"
                formControlName="password"
                placeholder="Password"
                autocomplete="new-password"
                aria-label="Password"
              />
              <div class="sign-in-password-icon">
                <button type="button" (click)="onTypeChange($event)">
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                      [attr.fill]="
                        passwordType === 'password' ? '#CAC7C7' : '#333333'
                      "
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div
              [ngClass]="{
                error:
                  passwordFormControl.touched && passwordFormControl.invalid
              }"
              class="mt-4 w-[310px] hint"
              *ngIf="passwordFormControl.invalid"
            >
              <p>Password must contain:</p>
              <p>&#8226; Uppercase, lowercase and numbers.</p>
              <p>&#8226; Between 8 to 32 characters.</p>
            </div>

            <div class="sign-in-input mt-4 relative w-[310px] h-[55px]">
              <!-- [ngClass]="{
              'mt-4': !passwordFormControl.invalid,
              'mt-4': passwordFormControl.invalid
            }" -->
              <div class="sign-in-icon">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 12C20 10.897 19.103 10 18 10H17V7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7V10H6C4.897 10 4 10.897 4 12V20C4 21.103 4.897 22 6 22H18C19.103 22 20 21.103 20 20V12ZM9 7C9 5.346 10.346 4 12 4C13.654 4 15 5.346 15 7V10H9V7Z"
                    fill="#333333"
                  />
                </svg>
              </div>
              <input
                [type]="passwordTypeRetype"
                formControlName="verifyPassword"
                placeholder="Repeat Password"
                autocomplete="new-password"
                aria-label="Repeat password"
              />
              <div class="sign-in-password-icon">
                <button type="button" (click)="onRetypeChange($event)">
                  <svg
                    width="22"
                    height="16"
                    viewBox="0 0 22 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5ZM11 13C8.24 13 6 10.76 6 8C6 5.24 8.24 3 11 3C13.76 3 16 5.24 16 8C16 10.76 13.76 13 11 13ZM11 5C9.34 5 8 6.34 8 8C8 9.66 9.34 11 11 11C12.66 11 14 9.66 14 8C14 6.34 12.66 5 11 5Z"
                      [attr.fill]="
                        passwordTypeRetype === 'password'
                          ? '#CAC7C7'
                          : '#333333'
                      "
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div class="mt-4 flex justify-center">
            <button
              type="submit"
              [disabled]="form.invalid || loading"
              class="action-btn w-[310px] h-[55px]"
            >
              <svg
                *ngIf="loading; else notLoading"
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <ng-template #notLoading> Submit </ng-template>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #expiredTokenTpl>
  <div>
    <div class="flex justify-center pb-4 border-b bg-white border-[#100440]">
      <svg-icon src="assets/svg/logo-purple.svg" svgClass="h-16"></svg-icon>
    </div>
    <p class="mt-8 text-lg text-[#100440] font-light max-w-md text-center">
      Invitation is no longer valid, please contact your administrator to send a
      new Invitation.
    </p>
  </div>
</ng-template>
