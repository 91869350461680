import { animate, style, transition, trigger } from "@angular/animations";

export const inOutAnimation = trigger(
  'inOutAnimation',
  [
    transition(
      ':enter',
      [
        style({ opacity: 0, transform: 'scale(95%)' }),
        animate('75ms ease-out',
                style({ opacity: 1, transform: 'scale(100%)' }))
      ]
    )
    ,transition(
      ':leave',
      [
        style({ transform: 'scale(100%)', opacity: 1 }),
        animate('75ms ease-in',
                style({ opacity: 0, transform: 'scale(95%)' }))
      ]
    )
  ]
)
