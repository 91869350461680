<div [ngClass]="toastType" class="container flex items-center gap-x-2">
  <span *ngIf="toastType === 'toast-error'">
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.3867 8V13" stroke="#F1F1FF" stroke-linecap="round" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3867 17C12.939 17 13.3867 16.5523 13.3867 16C13.3867 15.4477 12.939 15 12.3867 15C11.8344 15 11.3867 15.4477 11.3867 16C11.3867 16.5523 11.8344 17 12.3867 17Z"
        fill="#F1F1FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3867 22C17.9096 22 22.3867 17.5228 22.3867 12C22.3867 6.47715 17.9096 2 12.3867 2C6.86387 2 2.38672 6.47715 2.38672 12C2.38672 17.5228 6.86387 22 12.3867 22Z"
        stroke="#F1F1FF"
      />
    </svg>
  </span>
  <span *ngIf="toastType === 'toast-success'">
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.17984 15.8749L5.00984 11.7049L3.58984 13.1149L9.17984 18.7049L21.1798 6.70492L19.7698 5.29492L9.17984 15.8749Z"
        fill="#F1F1FF"
      />
    </svg>
  </span>
  <span> {{ toastPackage.message }}</span>
</div>
