import {gql} from 'apollo-angular';


export default gql`
mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(
    input: $input
  )
}
`;
