import { Apollo } from "apollo-angular";
import {
  Component,
  HostListener,
  ElementRef,
  ViewChild,
  OnInit,
} from "@angular/core";
import {
  Router,
  RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  ActivatedRoute,
  Scroll,
} from "@angular/router";
import { map, tap, filter, take } from "rxjs/operators";

import packageInfo from "../../package.json";
import { environment } from "src/environments/environment";
import { NgxRolesService } from "ngx-permissions";
import { UIPermissions } from "src/auth/permissions/permissions";
import { AuthNewService } from "src/auth/services/auth-new.service";
import { inOutAnimation } from "./utils/animations/in-out.animation";
import { GlobalLoadingService } from "./services/global-loading.service";
import { ApaiEnabledService } from "./modules/apai/services/apai-enabled.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [inOutAnimation],
})
export class AppComponent implements OnInit {
  title = "w2v-management-app";
  version = null;

  expires$ = this.auth.expires$;
  isNavZindex = false;
  //
  open = false;
  openMenu = false;
  loading = true;
  loading$ = this.globalLoadingService.isLoading$;

  auth$ = this.auth.auth$;
  fullname$ = this.auth.auth$.pipe(
    filter((data) => !!data),
    map((auth) => auth.payload),
    map(({ firstName, lastName }) => `${lastName}, ${firstName}`)
  );

  isApaiEnabled = false;

  loggedIn = this.auth.auth$;

  @ViewChild("clickOutside") clickOutside: ElementRef;

  @HostListener("document:click", ["$event.target"])
  public onClick(target) {
    const clickedInside = this.clickOutside?.nativeElement.contains(target);
    if (!clickedInside) {
      this.openMenu = false;
    }
  }

  constructor(
    private auth: AuthNewService,
    private router: Router,
    private route: ActivatedRoute,
    private rolesService: NgxRolesService,
    private globalLoadingService: GlobalLoadingService,
    private apaiEnabledService: ApaiEnabledService
  ) {
    // this.mixpanel.startTracking();

    this.router.events.subscribe((e: any) => {
      // console.log("navigate ", e);
      if (
        e instanceof NavigationEnd &&
        (e.url === "/change-password" || e.url === "/auth/change-password")
      ) {
        this.isNavZindex = true;
      } else if (!(e instanceof Scroll)) {
        this.isNavZindex = false;
      }
      this.navigationInterceptor(e);
    });

    this.route.queryParamMap
      .pipe(
        map((params) => params.has("version")),
        filter((version) => !!version),
        tap((t) => {
          this.version = packageInfo.version;
        })
      )
      .subscribe();

    if (!environment.production) {
      this.version = packageInfo.version;
    }
  }

  ngOnInit(): void {
    this.apaiEnabledService.fetchConfig();
    this.apaiEnabledService.enabledCompanies$.subscribe((enabled) => {
      if (
        enabled &&
        (enabled.userRole === "Admin" || enabled.companies.length)
      ) {
        this.isApaiEnabled = true;
      }
    });
  }

  signOut() {
    this.auth.signOut();
  }

  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
      const navState = this.router.getCurrentNavigation();
      /**preventLoader in state will prevent global loader
       * globalLoader always shown on first navigation when previousNavigation is null
       */
      if (
        !navState?.extras?.state?.["preventLoader"] ||
        !navState.previousNavigation
      )
        this.globalLoadingService.setGlobalLoading(true);
    }

    if (event instanceof NavigationEnd) {
      // this.loading = false;

      this.globalLoadingService.setGlobalLoading(false);
    }

    if (event instanceof NavigationCancel) {
      // this.loading = false;
      this.globalLoadingService.setGlobalLoading(false);
    }

    if (event instanceof NavigationError) {
      // this.loading = false;
      this.globalLoadingService.setGlobalLoading(false);
    }
  }

  get production() {
    return environment.production;
  }

  get role() {
    return Object.keys(this.rolesService.getRoles());
  }

  get permissions() {
    return UIPermissions;
  }
}
