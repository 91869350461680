import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY } from "rxjs";
import { catchError } from "rxjs/operators";
import { emailPattern } from "src/app/app.constants";
import { AuthNewService } from "src/auth/services/auth-new.service";
@Component({
  selector: "app-request-reset",
  templateUrl: "./request-reset.component.html",
  styleUrls: ["./request-reset.component.scss"],
})
export class RequestResetComponent implements OnInit {
  form = this.fb.group(
    {
      email: ["", [Validators.required, Validators.pattern(emailPattern)]],
    },
    { updateOn: "change" }
  );

  loading = false;

  get formControls() {
    return this.form.controls;
  }

  get isInvalid() {
    return (
      this.formControls.email &&
      this.formControls.email.touched &&
      this.formControls.email.invalid
    );
  }

  get isFormInvalid() {
    return this.form.invalid;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthNewService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.auth
      .forgotPassword(this.form.value.email)
      .pipe(
        catchError(() => {
          this.loading = false;
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.loading = false;
        this.router.navigate(["complete"], {
          relativeTo: this.route,
          state: { userEmail: this.form.value.email, preventLoader: true },
        });
      });
  }
}
