import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AuthRoutingModule } from "./auth-routing.module";
import { SignInComponent } from "./views/sign-in/sign-in.component";
import { ReactiveFormsModule } from "@angular/forms";
import { RequestResetComponent } from "./views/request-reset/request-reset.component";
import { ResetLinkSentComponent } from "./views/reset-link-sent/reset-link-sent.component";
import { PasswordResetFormComponent } from "./views/password-reset-form/password-reset-form.component";
import { ChangePasswordComponent } from "./views/change-password/change-password.component";
import { AngularSvgIconModule } from "angular-svg-icon";
import { TempViewComponent } from "./views/temp-view/temp-view.component";
import { SignUpComponent } from "./views/sign-up/sign-up.component";
import { ComponentsModule } from "src/app/components/components.module";
import { AppDirectivesModule } from "src/app/directives/app-directives.module";
import { ShellAuthComponent } from "./views/shell/shell.component";

@NgModule({
  declarations: [
    SignInComponent,
    RequestResetComponent,
    ResetLinkSentComponent,
    PasswordResetFormComponent,
    ChangePasswordComponent,
    TempViewComponent,
    SignUpComponent,
    ShellAuthComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    AppDirectivesModule,
    AngularSvgIconModule,
    ReactiveFormsModule,
    AuthRoutingModule,
  ],
})
export class AuthModule {}
