import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { catchError, finalize, first, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthNewService } from "src/auth/services/auth-new.service";
import { EMPTY } from "rxjs";
import { emailPattern, passwordPattern } from "src/app/app.constants";

@Component({
  selector: "app-auth-shell",
  templateUrl: "./shell.component.html",
  styleUrls: ["./shell.component.scss"],
})
export class ShellAuthComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
