import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, pluck } from "rxjs/operators";

interface State {
  token: string;
}

const defaultState: State = {
  token: "",
};

@Injectable({
  providedIn: "root",
})
export class AppStateService {
  private subject = new BehaviorSubject<State>(defaultState);
  private store = this.subject.asObservable().pipe(distinctUntilChanged());

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name)) as Observable<T>;
  }

  set(name: string, state: any) {
    // console.log(`-- set store value, [name]: ${name}  --`);

    this.subject.next({
      ...this.value,
      [name]: state,
    });
  }
}
