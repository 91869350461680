<div class="container-login grid grid-cols-3">
  <div
    class="col-span-2 logo-container relative flex items-center justify-center"
  >
    <div class="circle-inner"></div>
    <div class="circle-outer"></div>
    <svg-icon svgClass="svgSize" src="assets/svg/logo-login.svg"></svg-icon>
  </div>
  <div class="w-full">
    <router-outlet></router-outlet>
  </div>
</div>
