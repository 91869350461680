import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { EMPTY, catchError, tap } from "rxjs";
import { passwordPattern } from "src/app/app.constants";
import { MustMatch, MustNotMatch } from "src/app/utils/validators/must-match";
import { AuthNewService } from "src/auth/services/auth-new.service";

interface ChangePasswordModel {
  currentPassword: string;
  newPassword: string;
}

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
  form = this.fb.group(
    {
      oldPassword: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(passwordPattern),
          ],
          updateOn: "change",
        },
      ],
      newPassword: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(passwordPattern),
          ],
          updateOn: "change",
        },
      ],
      newPasswordRetype: [
        null,
        {
          validators: [
            Validators.required,
            Validators.pattern(passwordPattern),
          ],
          updateOn: "change",
        },
      ],
    },
    {
      validators: [
        MustMatch("newPassword", "newPasswordRetype"),
        // MustNotMatch("newPassword", "oldPassword"),
      ],
      updateOn: "change",
    }
  );

  loading = false;

  passwordChanged = false;

  get formNewPaswordControl() {
    return this.form.controls.newPassword;
  }

  get formOldPaswordControl() {
    return this.form.controls.oldPassword;
  }

  get formNewPasswordRetype() {
    return this.form.controls.newPasswordRetype;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthNewService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onOk() {
    this.router.navigate(["/app", "expenses", "expenses"], {
      state: { preventLoader: true },
    });
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const { oldPassword: currentPassword, newPassword } = this.form.value;
    this.loading = true;
    this.auth
      .updatePassword(currentPassword, newPassword)
      .pipe(
        tap(() => {
          this.passwordChanged = true;
          // this.router.navigate(["/"]);
        }),
        catchError((e) => {
          this.loading = false;
          return EMPTY;
        })
      )
      .subscribe();
  }
}
