import {gql} from 'apollo-angular';


export default gql`
mutation InviteUser($input: InviteUserInput!) {
  inviteUser(input: $input) {
    id
  }
}
`;
