import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler, APP_INITIALIZER } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { ToastrModule } from "ngx-toastr";

import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HelloWorldComponent } from "./components/hello-world/hello-world.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { ConfigurationComponent } from "./views/configuration/configuration.component";
import { SettingsComponent } from "./views/settings/settings.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { GraphQLModule } from "./graph-ql.module";
import { NgxPermissionsModule } from "ngx-permissions";
import { environment } from "src/environments/environment";
import { ErrorsImprovedInterceptor } from "./services/errors-improved.interceptor";
import { OverlayModule } from "@angular/cdk/overlay";
import { AuthModule } from "src/auth/auth.module";
import { AngularSvgIconModule } from "angular-svg-icon";
import { ComponentsModule } from "./components/components.module";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsFormPluginModule } from "@ngxs/form-plugin";
import { JwtInterceptor } from "./services/jwt.interceptor";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { ToastComponent } from "./shared/toast-component/toast-component.component";
import { MatNativeDateModule, DateAdapter } from "@angular/material/core";
// import {provideNativeDateAdapter} from '@angular/material/core';
import { provideEnvironmentNgxMask } from "ngx-mask";

@NgModule({
  declarations: [
    AppComponent,
    HelloWorldComponent,
    NavbarComponent,
    ConfigurationComponent,
    SettingsComponent,
    ToastComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ComponentsModule,
    HttpClientModule,
    AuthModule,
    OverlayModule,
    MatNativeDateModule,
    AngularSvgIconModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    GraphQLModule,
    ClipboardModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
      selectorOptions: {
        // These Selector Settings are recommended in preparation for NGXS v4
        // (See above for their effects)
        suppressErrors: false,
        injectContainerState: false,
      },
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({ disabled: environment.production }),

    // NgxGoogleAnalyticsModule.forRoot(environment.analyticsTrackingCode),
    // NgxGoogleAnalyticsRouterModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-right",
      timeOut: 5000,
      // extendedTimeOut: 3000,
      toastComponent: ToastComponent,
      maxOpened: 1,
      preventDuplicates: true,
    }),
    ReactiveFormsModule,
    AppRoutingModule,
    /* You should always include the devtools as the last plugin in your configuration. (https://www.ngxs.io/plugins/devtools) */
    NgxsFormPluginModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsImprovedInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    provideEnvironmentNgxMask(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
