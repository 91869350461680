import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { catchError, ReplaySubject, Subject, tap } from "rxjs";
import { Store } from "@ngxs/store";
import { SetFilter } from "../state/apai.actions";

interface CompaniesEnabled {
  userId: number;
  userRole: string;
  companies: {
    id: string;
    name: string;
    enabled: boolean;
  }[];
}

@Injectable({
  providedIn: "root",
})
export class ApaiEnabledService {
  enabledCompaniesSubject = new ReplaySubject<CompaniesEnabled>();
  enabledCompanies$ = this.enabledCompaniesSubject.asObservable();
  enabledCompanies: CompaniesEnabled;
  constructor(private http: HttpClient, private ngxsStore: Store) {}
  isTokenValid(token: string) {
    if (!token) return false;
    const parsedToken = JSON.parse(atob(token.split(".")[1]));
    const exp = Number(parsedToken.exp * 1000);

    return exp > Date.now();
  }
  fetchConfig() {
    const token = localStorage.getItem("access_token");
    const isValid = this.isTokenValid(token);
    if (isValid) {
      this.http
        .get<CompaniesEnabled>(
          environment.apai + `/api/apai/company/isAPAIEnabled`,
          {
            headers: new HttpHeaders({
              Authorization: "Bearer " + token,
            }),
          }
        )
        .pipe(
          tap((data) => {
            this.enabledCompanies = data;
            this.enabledCompaniesSubject.next(data);
            if (data.companies.length) {
              const company = data.companies[0];

              this.ngxsStore.dispatch(
                new SetFilter({
                  companyId: company.id,
                  page: 1,
                })
              );
            }
          })
        )
        .subscribe();
    }
  }
}
