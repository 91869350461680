import { UntypedFormGroup } from "@angular/forms";

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    console.log("-- must match --");

    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    // if (matchingControl.errors && !matchingControl.errors.mustMatch) {
    //   // return if another validator has already found an error on the matchingControl
    //   return null;
    // }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      console.log("not match");
      matchingControl.setErrors({ mustMatch: true });
    } else {
      console.log("match");
      matchingControl.setErrors(null);
    }

    return null;
  };
}

export function MustNotMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustNotMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value === matchingControl.value) {
      console.log("not match");
      matchingControl.setErrors({ mustNotMatch: true });
    } else {
      console.log("match");
      matchingControl.setErrors(null);
    }
  };
}
