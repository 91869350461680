import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class GlobalLoadingService {
  private isLoadingSubject$ = new BehaviorSubject(false);
  isLoading$ = this.isLoadingSubject$.asObservable();
  constructor() {}

  setGlobalLoading(loading: boolean) {
    this.isLoadingSubject$.next(loading);
  }
}
