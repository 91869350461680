import {
  APOLLO_OPTIONS,
  APOLLO_NAMED_OPTIONS,
  ApolloModule,
} from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { InMemoryCache, ApolloLink } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import extractFiles from "extract-files/extractFiles.mjs";
import isExtractableFile from "extract-files/isExtractableFile.mjs";
import { environment } from "src/environments/environment";
import { NamedOptions } from "apollo-angular/types";
import { onError } from "@apollo/client/link/error";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

const uri = `${environment.transformerQl}`;
const core = `${environment.core}`;
const wv = `${environment.wvgql}`;
const phoenix = `${environment.phoenixGql}`;
const authUrl = `${environment.authGql}`;
const apai = `${environment.apaigql}`;

export function provideApollo(
  httpLink: HttpLink,
  router: Router,
  toastr: ToastrService
) {
  const basic = setContext((operation, context) => ({
    headers: {
      // Accept: 'charset=utf-8'
      "apollo-require-preflight": "true",
    },
  }));

  const link = ApolloLink.from([
    basic,
    /* auth, */ httpLink.create({
      uri,
      extractFiles: (body) => extractFiles(body, isExtractableFile),
    }),
  ]);
  const cache = new InMemoryCache();

  return {
    link,
    cache,
    defaultOptions: {
      query: {
        fetchPolicy: "no-cache",
      },
    },
    connectToDevTools: true,
  };
}

export function provideNamed(
  httpLink: HttpLink,
  router: Router,
  toastr: ToastrService
): NamedOptions {
  const errorLink = onError(({ graphQLErrors, networkError, response }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, path, extensions }) => {
        console.log({ message, path, extensions });
      });
  });

  const basic = setContext((operation, context) => ({
    headers: {
      // Accept: 'charset=utf-8'
      "apollo-require-preflight": "true",
    },
  }));

  return {
    auth: {
      link: ApolloLink.from([
        basic,
        errorLink,
        httpLink.create({
          uri: authUrl,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
      ]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    },
    extra: {
      link: ApolloLink.from([
        basic,
        errorLink,
        httpLink.create({
          uri: core,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "no-cache",
        },
      },
      connectToDevTools: true,
    },
    phoenix: {
      link: ApolloLink.from([
        basic,
        errorLink,
        httpLink.create({
          uri: phoenix,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        query: {
          fetchPolicy: "no-cache",
        },
      },
      connectToDevTools: true,
    },
    wv: {
      link: ApolloLink.from([
        basic,
        errorLink,
        httpLink.create({
          uri: wv,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
      ]),
      defaultOptions: {
        query: {
          fetchPolicy: "no-cache",
        },
      },
      cache: new InMemoryCache({
        typePolicies: {
          ClaimReconciliation: {
            merge: true,
          },
        },
      }),
      connectToDevTools: true,
    },
    apai: {
      link: ApolloLink.from([
        basic,
        errorLink,
        httpLink.create({
          uri: apai,
          extractFiles: (body) => extractFiles(body, isExtractableFile),
        }),
      ]),
      defaultOptions: {
        query: {
          fetchPolicy: "no-cache",
        },
      },
      cache: new InMemoryCache(),
      connectToDevTools: true,
    },
  };
}

@NgModule({
  imports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApollo,
      deps: [HttpLink, Router, ToastrService],
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: provideNamed,
      deps: [HttpLink, Router, ToastrService],
    },
  ],
})
export class GraphQLModule {
  constructor() {}
}
