import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { tap, switchMap, map, filter, pluck } from "rxjs/operators";
import * as Sentry from "@sentry/angular-ivy";
import { NgxRolesService } from "ngx-permissions";
import {
  ALL_PERMISSIONS,
  ANALYST_PERMISSIONS,
  USER_PERMISSIONS,
} from "../permissions/permissions";
import { ToastrService } from "ngx-toastr";
// import mixpanel from 'mixpanel-browser';
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { Apollo } from "apollo-angular";
import {
  Login,
  LoginVariables,
  Login_login,
} from "src/models/phoenix-auth/Login";
import {
  RefreshToken,
  RefreshTokenVariables,
} from "../../models/phoenix-auth/RefreshToken";
import {
  ResetPassword,
  ResetPasswordVariables,
} from "../../models/phoenix-auth/ResetPassword";
import {
  ForgotPassword,
  ForgotPasswordVariables,
} from "../../models/phoenix-auth/ForgotPassword";
import {
  FinishInviteUserMutation,
  ForgetPasswordMutation,
  LoginMutation,
  RefreshTokenMutation,
  ResetPasswordMutation,
} from "../../app/queries/phoenix-auth/auth";
import {
  FinishInviteInput,
  RefreshTokenInput,
} from "../../models/phoenix-auth/globalTypes";
import { FinishUserInvite } from "../../models/phoenix-auth/FinishUserInvite";
import { ChangePasswordVariables } from "../../models/phoenix/ChangePassword";
import { ChangePassword } from "../../app/queries/phoenix/users";
import { Router } from "@angular/router";

const NO_REFRESH = true;

const permissionsByRoles = {
  Admin: [...ALL_PERMISSIONS],
  Analyst: [...ANALYST_PERMISSIONS],
  User: [...USER_PERMISSIONS],
};

@Injectable({
  providedIn: "root",
})
export class AuthNewService {
  private authSubject = new BehaviorSubject<Login_login | null>(null);
  auth$: Observable<Login_login | null> = this.authSubject.asObservable();

  url = environment.auth + "/login";

  public get userValue(): Login_login | null {
    return this.authSubject.value;
  }

  constructor(
    private http: HttpClient,
    private rolesService: NgxRolesService,
    private toastr: ToastrService,
    private apollo: Apollo,
    protected gaService: GoogleAnalyticsService,
    private router: Router
  ) {
    const auth = JSON.parse(localStorage.getItem("way2vat"));

    if (auth) {
      this.authSubject.next({ ...auth });

      this.rolesService.addRoleWithPermissions(
        auth.payload.role,
        permissionsByRoles[auth.payload.role.toString()]
      );
    }
  }

  get expires$() {
    return this.auth$.pipe(
      pluck("access_token"),
      filter((accessToken) => !!accessToken),
      map((accessToken) => {
        const jwtToken = JSON.parse(atob(accessToken.split(".")[1]));

        return new Date(jwtToken.exp * 1000);
      })
    );
  }

  refresh = (
    input: RefreshTokenInput = {
      refresh_token: localStorage.getItem("refresh_token"),
    }
  ) => {
    // console.log(
    // `-- refresh was called, time: ${new Date(Date.now())} -- `,
    //   this
    // );

    const variables: RefreshTokenVariables = {
      input,
    };

    return this.apollo
      .use("auth")
      .mutate<RefreshToken>({
        mutation: RefreshTokenMutation,
        variables,
      })
      .pipe(
        map((response) => response.data.refreshToken),
        tap((response) =>
          this.authSubject.next({
            ...this.userValue,
            access_token: response.access_token,
          })
        ),
        tap((refreshToken) =>
          localStorage.setItem("access_token", refreshToken.access_token)
        ),
        tap((response) => {
          this.startRefreshTokenTimer();
        })
      );
  };

  signIn(username, password) {
    console.log(`-- login, time: ${new Date(Date.now())} -- `);

    const variables: LoginVariables = {
      loginInput: {
        email: username,
        password,
      },
    };

    return this.apollo
      .use("auth")
      .mutate<Login>({
        mutation: LoginMutation,
        variables,
      })
      .pipe(
        map((response) => response.data.login),
        // tap(({ access_token, payload }) => console.log({ access_token, payload })),
        tap((response) => this.authSubject.next({ ...response })),
        tap(({ access_token, payload, refresh_token }) => {
          Sentry.setUser({
            email: payload.email,
            role: payload.role,
            name: `${payload.firstName} ${payload.lastName}`,
            id: payload.userId,
          });
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("refresh_token", refresh_token);
        }),
        tap((response) =>
          localStorage.setItem("way2vat", JSON.stringify(response))
        ),
        tap((response) => {
          this.rolesService.addRoleWithPermissions(
            response.payload.role,
            permissionsByRoles[response.payload.role.toString()]
          );
        }),
        tap((response) => NO_REFRESH || this.startRefreshTokenTimer()),
        switchMap((response) => this.auth$)
      );
  }

  signOut(): void {
    localStorage.removeItem("__way2vat.user");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("access_token");
    localStorage.removeItem("way2vat");

    this.apollo.use("extra").client.clearStore();
    this.apollo.use("wv").client.clearStore();
    this.apollo.use("phoenix").client.clearStore();

    this.rolesService.flushRolesAndPermissions();
    this.authSubject.next(null);

    NO_REFRESH || this.stopRefreshTokenTimer();

    this.router.navigate(["/sign-in"]);
  }

  forgotPassword(email) {
    const variables: ForgotPasswordVariables = {
      input: {
        email,
      },
    };

    return this.apollo.use("auth").mutate<ForgotPassword>({
      mutation: ForgetPasswordMutation,
      variables,
    });
  }

  updatePassword(currentPassword, newPassword) {
    const variables: ChangePasswordVariables = {
      input: {
        currentPassword,
        newPassword,
      },
    };

    return this.apollo
      .use("phoenix")
      .mutate({
        mutation: ChangePassword,
        variables,
      })
      .pipe(tap(() => this.toastr.success("Password Changed successfully")));
  }

  setNewPassword(newPassword, token) {
    const variables: ResetPasswordVariables = {
      input: {
        newPassword,
        token,
      },
    };

    return this.apollo
      .use("auth")
      .mutate<ResetPassword>({
        mutation: ResetPasswordMutation,
        variables,
      })
      .pipe(
        tap((response) => this.toastr.success("Password changed successfully"))
      );
  }

  finishInvite(input: FinishInviteInput) {
    return this.apollo
      .use("auth")
      .mutate<FinishUserInvite>({
        mutation: FinishInviteUserMutation,
        variables: {
          input,
        },
      })
      .pipe(
        map((response) => response.data.finishUserInvite),
        tap(() => this.toastr.success("User created successfully"))
        // tap((response) => console.log("--2", response))
      );
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    if (this.refreshTokenTimeout) {
      this.stopRefreshTokenTimer();
    }
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(
      atob(localStorage.getItem("access_token").split(".")[1])
    );

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);

    console.log(`-- expires at, time: ${expires} -- `);

    const timeout = expires.getTime() - Date.now() - 60 * 1000;

    console.log(
      `-- timeout at, time: ${new Date(expires.getTime() - 60 * 1000)} -- `
    );

    this.refreshTokenTimeout = setTimeout(
      () => this.refresh().subscribe(),
      timeout
    );
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
