import { Component, OnInit } from "@angular/core";
import {
  AbstractControlOptions,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { passwordPattern } from "src/app/app.constants";
import { MustMatch } from "src/app/utils/validators/must-match";
import { AuthNewService } from "src/auth/services/auth-new.service";
import { FinishInviteInput } from "src/models/phoenix-auth/globalTypes";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  passwordType = "password";
  passwordTypeRetype = "password";
  expiration(token) {
    try {
      const jwtToken = JSON.parse(atob(token.split(".")[1]));
      return new Date(jwtToken.exp * 1000);
    } catch (e) {
      return null;
    }
  }

  get validToken() {
    return (
      this.token &&
      this.expiration(this.token) &&
      Date.now() - this.expiration(this.token)?.getTime() < 0
    );
  }

  token = null;

  options: AbstractControlOptions = {
    validators: MustMatch("password", "verifyPassword"),
  };

  form = this.fb.group(
    {
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      password: [
        null,
        [Validators.required, Validators.pattern(passwordPattern)],
      ],
      verifyPassword: [null, Validators.required],
    },
    this.options
  );

  get passwordFormControl() {
    return this.form.controls.password;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private auth: AuthNewService,
    private router: Router
  ) {
    this.token = this.route.snapshot.queryParamMap.get("token");
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const { firstName, lastName, password } = this.form.value;

    const request = {
      firstName,
      lastName,
      password,
      token: this.token,
    } as FinishInviteInput;

    this.auth.finishInvite(request).subscribe((response) => {
      this.router.navigateByUrl("/");
    });
  }

  get loading() {
    return false;
  }
  onTypeChange(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    switch (this.passwordType) {
      case "password":
        return (this.passwordType = "text");
      case "text":
        return (this.passwordType = "password");
      default: {
        return (this.passwordType = "password");
      }
    }
  }

  onRetypeChange(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    switch (this.passwordTypeRetype) {
      case "password":
        return (this.passwordTypeRetype = "text");
      case "text":
        return (this.passwordTypeRetype = "password");
      default: {
        return (this.passwordTypeRetype = "password");
      }
    }
  }
}
