import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { Integration } from "@sentry/types";
import { BrowserTracing } from "@sentry/tracing";

const sentryIntegrations = [
  new Sentry.Integrations.Breadcrumbs({
    console: false,
  }),
  new Sentry.BrowserTracing({
    traceXHR: true,
    traceFetch: true,
    startTransactionOnPageLoad: true,
    tracePropagationTargets: [
      /https:\/\/api-gateway-dev\.way2vat\.com\/.*/,
      /https:\/\/api-gateway\.way2vat\.com\/.*/,
    ],
    routingInstrumentation: Sentry.routingInstrumentation,
  }) as unknown as Integration,
];

if (environment.production) {
  enableProdMode();
  sentryIntegrations.push(
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    })
  );
}

Sentry.init({
  tracesSampleRate: 0.25,
  dsn: "https://d8763be603de4fbeb5c4430bddd739a7@o1088999.ingest.sentry.io/4505430623453184",
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.25,
  tracePropagationTargets: [
    /https:\/\/api-gateway-dev\.way2vat\.com\/.*/,
    /https:\/\/api-gateway\.way2vat\.com\/.*/,
  ],
  autoSessionTracking: true,
  sampleRate: environment.production ? 0.25 : 1,
  environment: environment.name,
  enabled: environment.name !== "localhost",
  // @ts-ignore
  integrations: sentryIntegrations,
  beforeSend: (event, hint) => {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  },
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
