<div class="container mx-auto px-4 flex justify-center">

    <div class="w-full max-w-xl">

        <div class="bg-white shadow-md rounded px-4 py-4 mb-4">

            <div class="-mx-4 -mt-4 border-b text-blue-700 text-lg leading-none leading-none font-medium p-4">
                <h2> Settings </h2>
            </div>

            <form [formGroup]="form" class="p-4" (submit)="submit()">

                <div class="mb-4">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-password">
                        Token
                      </label>
                      <input formControlName="token" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                            id="grid-password" >
                      </div>

                <div class="flex items-center justify-end -mx-4 -mb-4">
                  <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:ring" type="submit">
                    Save
                  </button>
                </div>

            </form>

        </div>

      </div>


</div>


